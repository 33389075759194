<!--
 * @Author: Shirtiny
 * @Date: 2021-12-30 13:53:41
 * @LastEditTime: 2021-12-30 17:25:48
 * @Description:
-->
<template>
  <div class="player_puplayer-demo">
    <h3>视频播放 PuPlayer</h3>
    <p>
      <input v-model="id" type="text" placeholder="设备ID" />
      <label>通道号：</label
      ><input
            v-model.number="index"
            type="text"
            placeholder="通道号 （数字）"
    />
    </p>
    <p>
      <!-- 初始化和销毁 -->
      <template>
        <button v-if="!instance" @click="init">初始化播放器</button>
        <button v-else @click="destroy">销毁播放器</button>
      </template>
    </p>
    <div ref="playerContainer" class="player-container"></div>
    <p v-if="instance">
      <!-- 打开和关闭 -->
      <template>
        <button v-if="closed" @click="open">建立连接</button>
        <button v-else @click="close">关闭连接</button>
      </template>

      <!-- 视频如何适应容器 -->
      <template>
        <label>适应容器：</label>
        <select :value="videoFit" @change="handleVideoFit">
          <option>fill</option>
          <option>contain</option>
        </select>
      </template>

      <!-- 显示和隐藏 -->
      <template>
        <button v-if="hidden" @click="display">显示</button>
        <button v-else @click="hide">隐藏</button>
      </template>

      <!-- 移动和更换容器 -->
      <template>
        <button @click="move">更换容器</button>
      </template>
    </p>
    <div ref="playerContainer2" class="player-container2"></div>
    <h3>回调</h3>
    <pre>{{ messages.join("\n") }}</pre>
  </div>
</template>

<script>
  // import { PuPlayer } from "@besovideo/webrtc-player"; // 视频
  import { Intercom} from "@besovideo/webrtc-player"; // 对讲
  // import { Conference } from "@besovideo/webrtc-player"; // 对讲

  export default {
    name: "PuPlayerDemo",
    props: {
      token: String,
    },
    data() {
      return {
        id: "PU_2430C6C5",
        index: 0,
        videoFit: "fill",

        instance: null,
        closed: true,
        hidden: false,
        moved: false,
        messages: [],
      };
    },
    methods: {
      // 初始化
      init() {
        if (!this.token || !this.id) return;
        // 视频
        // const { instance } = PuPlayer({
        //   // (可选) 容器节点 注意一个容器内只能存在一个实例  当container为假值（包括false、null、undefined）时 将返回实例引用的dom节点 容器必须指定高度 参考高德地图
        //   container: this.$refs.playerContainer,
        //   // 必填 设备选项
        //   puOption: {
        //     // 设备id
        //     id: this.id,
        //     // 设备通道号
        //     index: 0,
        //   },
        //   // 必填 用户授权令牌
        //   token: this.token,
        //   videoFit: this.videoFit,
        //   // (可选) 双击是否全屏
        //   fullScreenOnDblclick: true,
        //   // (可选) 启用控制器
        //   enableController: true,
        //   onConnected: () => {
        //     this.messages.push("onConnected 执行");
        //   },
        //   onConnectedFailed: () => {
        //     this.messages.push("onConnectedFailed 执行");
        //   },
        //   onDisConnected: () => {
        //     this.messages.push("onDisConnected 执行");
        //   },
        // });
        // 对讲
        const { instance } = Intercom({
          // 必填 设备选项
          puOption: {
            // 设备id
            id: this.id,
            // 设备通道号
            index: 0,
          },
          // 必填 用户授权令牌
          token: this.token,
          // (可选) 连接建立后回调
          onConnected: () => {},
          // (可选) 连接建立失败时的回调
          onConnectedFailed: () => {},
          // (可选) 连接断开后回调
          onDisConnected: () => {},
          // (可选) 无法获取用户麦克风时
          onGetUserMediaFailed: () => {},
          // (可选) 指定请求url的前缀 方便代理请求
          // apiPrefix: "/test_api/prefix",
        });
        // 会议
        // const { instance } = Conference({
        //   // 必填 设备选项
        //   confOption: {
        //     // 会议id
        //     id: "test89312",
        //     // 会议成员ID
        //     index: 0,
        //   },
        //   // 必填 用户授权令牌
        //   token: this.token,
        //   // (可选) 连接建立后回调
        //   onConnected: () => {},
        //   // (可选) 连接建立失败时的回调
        //   onConnectedFailed: () => {},
        //   // (可选) 连接断开后回调
        //   onDisConnected: () => {},
        //   //(可选) 无法获取用户麦克风时
        //   onGetUserMediaFailed: () => {},
        //   // (可选) 指定请求url的前缀 方便代理请求
        //   // apiPrefix: "/test_api/prefix",
        // });
        this.instance = instance;
      },
      // 销毁
      destroy() {
        // destroy前会执行close
        this.instance?.destroy();
        this.instance = null;
        this.closed = true;
      },
      // 打开连接
      async open() {
        if (!this.instance) return;
        try {
          await this.instance.open();
          this.closed = false;
        } catch (e) {
          console.log(e);
        }
      },
      // 关闭连接
      close() {
        this.instance?.close();
        this.closed = true;
      },
      // 改变videoFit
      handleVideoFit(e) {
        if (!this.instance) return;
        const v = e.target.value;
        this.instance.setVideoFit(v);
        this.videoFit = v;
      },
      // 显示
      display() {
        this.instance?.display();
        this.hidden = false;
      },
      // 隐藏
      hide() {
        this.instance?.hidden();
        this.hidden = true;
      },
      // 移动和更换容器
      move() {
        this.instance?.moveTo(
                this.moved ? this.$refs.playerContainer : this.$refs.playerContainer2
        );
        this.moved = !this.moved;
      },
    },
    mounted() {},
  };
</script>

<style scoped lang="less">
  .player_puplayer-demo {
    .player-container {
      height: 350px;
      width: 500px;
      border: 1px solid #ddd;
    }
    .player-container2 {
      margin-top: 8px;
      height: 250px;
      width: 330px;
      border: 1px solid #ddd;
    }
    pre {
      line-height: 1.5;
      font-size: 15px;
    }
  }
</style>
